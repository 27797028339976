import { useContext } from "react"
import { useTranslation } from "react-i18next";
import { Cookie } from "../clases/CookieClass"
import { GlobalStates } from "../GlobalStates"
import i18n from "../Language/i18n"
import SingleSelect from "./Select/SingleSelect"

const KalbuPasirinkimas = (props) => {
    const { i18n: { language } } = useTranslation();
    const kalbos = new Cookie('kalbai')
    const { setLanguageG } = useContext(GlobalStates)
    const changeLanguage = (lng, _, event) => {
        i18n.changeLanguage(lng)
        kalbos.setCookie(lng)
        setLanguageG(lng)
        // neissivercia grafiku tekstai, reikia rerenderint manual
        // event.preventDefault();
    }

    if (props.options && props.options.length > 0 && props.optionsText.length > 0)
        return (
            <SingleSelect
                sx0={{ minWidth: 0 }}
                sx1={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                // sx0={{ minWidth: 0 }}
                classNameSelectLabel='kalbaSelectLabel'
                label={language || ''}
                options={props.options}
                optionsText={props.optionsText}
                sx={{ m: 1, width: 70 }}
                className={props.className + ' kalbaSelect'}
                classNameBox={props.classNameBox || ''}
                set={changeLanguage}
                size='small'
                current={language}
            />

        )
    else { return null }
}
export default KalbuPasirinkimas