import './App.css';
import './vokietis.css';
import './Components/PrimaryTextField/PrimaryTextField.css';
import './Components/CheckBox/CheckBox.css';
import '../src/Navigation/NavBar.css';
import './Components/Css/login.css';
import './Components/Css/user.css';
import './Components/Css/proflisPrideti.css';
import './Components/Css/Formos.css';
import './Components/Css/rezTend.css';
import './Components/Css/policies.css';
// import './Components/Css/apklausaVidus.css';
import './Components/PrimaryAccordion/PrimaryAccordion.css';
import './Components/Css/Paulius.css';
import './Components/statistikaTable.css';
import React, { Suspense, useContext, useEffect, useMemo, useState } from 'react';
import './Language/i18n';
import Navigation from './Navigation/NavigationBar';
import { BrowserRouter } from "react-router-dom";
import TaskContextProvider, { GlobalStates } from "./GlobalStates"
import { Cookie } from './clases/CookieClass';
import axios from 'axios';
import { Loader } from './Components/Loader';
import Toasty from './Components/Alert/toasty-alert';
import i18n from './Language/i18n';

import { ThemeProvider } from '@mui/material/styles';
import { theme } from './muiTheme';

const Routes = React.lazy(() => import("./Navigation/Routes"));

function App() {
  return (
    <TaskContextProvider>
      <ThemeProvider theme={theme}>
        <Main />
      </ThemeProvider>
    </TaskContextProvider>

  );
}

const Main = () => {
  const cookie = useMemo(() => new Cookie('Formu'), []);
  const kalba = useMemo(() => new Cookie('kalbai'), []);

  const { user, setType, setUser, setToken, path, AlertText } = useContext(GlobalStates)

  useEffect(() => {
    if (cookie.getCookie()) {
      setToken(cookie.getCookie());
    }
  }, [cookie, setToken]);

  useEffect(() => {
    if (kalba.getCookie()) {
      i18n.changeLanguage(kalba.getCookie());
    }
  }, [kalba]);

  useEffect(() => {
    if (!user && cookie.getCookie()) {
      axios
        .get(path + 'users/me', {
          headers: {
            Authorization: `Bearer ${cookie.getCookie()}`,
          },
        })
        .then((response) => {
          if (response.data.is_admin) { setType(0) }
          else if (response.data.is_buhaltere) { setType(1) }
          else { setType(2) }
          // Handle success.
          setUser(response.data)
        })
        .catch((error) => {
          // Handle error.
          console.log('An error occurred:', error.response);
        });
    }
  }, [user, cookie, setUser, setType, path])

  return (
    <BrowserRouter>
      <div>
        <div className='Navigacija'>
          <Navigation />
        </div>
        <Toasty text={alert}
        />
        <div >
          <Suspense fallback={<Loader fullHeight />} >
            <Routes />
          </Suspense>
        </div>
      </div>
    </BrowserRouter>
  )
}

export default App;


