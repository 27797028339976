import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        primary: {
            main: '#665AE1'
        }
    },
    typography: {
        fontFamily: "Inter"
    }

})