import React, { useState, createContext, } from "react";

export const GlobalStates = createContext();

const TaskContextProvider = (props) => {
    const [tasks, setTasks] = useState('login');
    const [user, setUser] = useState('');
    const [token, setToken] = useState('');
    const [isLoading, setIsLoading] = useState(true)
    const [type, setType] = useState('')
    const [alert, setAlert] = useState('')
    const [languageG, setLanguageG] = useState('')
    // const [path, setPath] = useState('http://localhost:1337/api/')//local
    // const [pathImg, setPathImg] = useState('http://localhost:1337')//local for img
    const [path, setPath] = useState('https://ivairove-formos.herokuapp.com/api/')//global
    const [pathImg, setPathImg] = useState('https://ivairove-formos.herokuapp.com/')//global for img

    return (
        <GlobalStates.Provider value={{
            tasks, setTasks,
            user, setUser,
            token, setToken,
            path, setPath,
            pathImg, setPathImg,
            isLoading, setIsLoading,
            type, setType,
            alert, setAlert,
            languageG, setLanguageG
        }}>
            {props.children}
        </GlobalStates.Provider>
    );
};

export default TaskContextProvider

